import {API} from "./axios";

export const planificationsService = {
    addPlanification,
    updatePlanification,
    getPlanifications,
    delPlanification
};

// ####################################################################### ADD PLANIFICATION

async function addPlanification(params) {
    try{
        const res = await API.post(`/planifications/`, params);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### UPDATE PLANIFICATION

async function updatePlanification(params) {
    try{
        const res = await API.patch(`/planifications/${params.planificationId}`, {date: params.date});
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '400'){
            return Promise.reject(err.response.data.errors[0].messages[0]);
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### DELETE PLANIFICATION

async function delPlanification(id) {

    try{
        const res = await API.delete(`/planifications/${id}`);
        return res.data;
    }catch(err){
        if(err.response.status == '401' || err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else if(err.response.status == '404'){
            return Promise.reject("planification Not Found !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}

// ####################################################################### GET PLANIFICATIONS

async function getPlanifications(params){
    try{
        const res = await API.get(`/planifications`, {params: params});
        return res.data;
    }catch(err){
        if(err.response.status == '403'){
            return Promise.reject("Not Authorized !");
        }else{
            return Promise.reject("Veuillez réessayer plus tard !");
        }
    }
}
import { alertsService } from '../../helpers/backend/alerts.service';

export const state = {
    alerts: {},
    states: {},
    causes: {},
    actions: {},
    export: {},
    countByMarqueRegion: [],
    countByMarque: [],
}

export const getters = {
    getAlerts(state) {
        return state.alerts;
    },
    getCountByMarqueRegion(state){
        return state.countByMarqueRegion;
    },
    getStatsByMarque(state){
        return state.countByMarque;
    }
}

export const actions = {
    getAlertsBy({ commit }, params) {
        return alertsService.getAlerts(params)
            .then(
                alerts => {
                    commit("ALERTS_SUCCESS", alerts)
                    return Promise.resolve(alerts);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    aquitAlert({ commit }, params) {
        return alertsService.aquitAlert(params)
            .then(
                device => {
                    commit("AQUIT_ALERT_SUCCESS", device)
                    return Promise.resolve(device);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    aquitAlerts({ commit }, params) {
        return alertsService.aquitAlerts(params)
            .then(
                response => {
                    if (response.closed) {
                        return Promise.resolve({ closed: true });
                    } else {
                        commit("AQUIT_ALERTS_SUCCESS", response)
                        return Promise.resolve(response);
                    }

                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    aquitPartOfAlerts({ commit }, params) {
        return alertsService.aquitAlerts(params)
            .then(
                response => {
                    if (response.closed) {
                        commit("AQUIT_PART_OF_ALERTS_SUCCESS", response)
                        return Promise.resolve(params.serials);
                    } else {
                        commit("AQUIT_ALERTS_SUCCESS", response)
                        return Promise.resolve(response);
                    }

                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    exportXLSAlerts({commit}, params) {
        return alertsService.exportXLS(params)
            .then(
                res => {
                    if(res.status !== 204){
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        if(params.serial) link.setAttribute('download', `alerts--${params.from}-${params.to} ${params.serial}.xlsx`);
                        else link.setAttribute('download', `alerts--${params.from}-${params.to}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        commit("EXPORT_SUCCESS", res)
                        return Promise.resolve({status: true});
                    }else{
                        return Promise.resolve({status: false});
                    }
                    
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    clearAlerts({ commit }) {
        commit("CLEAR_ALERTS_SUCCESS")
    },
    getCountByMarqueRegionBy({commit}) {
        return alertsService.countByMarqueRegion()
            .then(
                count => {
                    commit("GET_COUNTMARQUEREGION_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    getStatsByMarqueBy({commit}) {
        return alertsService.countByMarque()
            .then(
                count => {
                    commit("GET_COUNTMARQUE_SUCCESS", count);
                    return Promise.resolve(count)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
}

export const mutations = {
    ALERTS_SUCCESS(state, alerts) {
        state.alerts = alerts;
    },
    EXPORT_SUCCESS(state, res) {
        state.export = res;
    },
    CLEAR_ALERTS_SUCCESS(state) {
        state.alerts = {};
    },

    AQUIT_ALERT_SUCCESS(state, device) {
        let newResults = []
        let alerts = device.alerts.map((obj) => {
            return obj.id;
        })
        if (device.closed) {
            newResults = state.alerts.results.filter(obj => !alerts.includes(obj.id))
        } else {
            newResults = state.alerts.results.map(obj => (alerts.includes(obj.id)) ? { ...obj, state: device.state } : obj)
        }
        state.alerts = { ...state.alerts, results: newResults }
    },
    AQUIT_ALERTS_SUCCESS(state, data) {
        let alerts = data.alerts.map((obj) => {
            return obj.id;
        })
        let newResults = state.alerts.results.map(obj => {
            if (data.alerts) {
                if (alerts.includes(obj.id)) {
                    return { ...obj, state: data.state };
                }
                else return obj;
            } else {
                return { ...obj, state: data.state };
            }

        })
        state.alerts = { ...state.alerts, results: newResults }
    },
    AQUIT_PART_OF_ALERTS_SUCCESS(state, response) {
        let newResults = []
        let alerts = response.alerts.map((obj) => {
            return obj.id;
        })
        state.alerts.results.map(obj => {
            if (!alerts.includes(obj.id)) {
                newResults.push(obj)
            }
        })
        state.alerts = { ...state.alerts, results: newResults }
    },
    GET_COUNTMARQUEREGION_SUCCESS(state, response){
        state.countByMarqueRegion = response;
    },
    GET_COUNTMARQUE_SUCCESS(state, response){
        state.countByMarque = response;
    }
}
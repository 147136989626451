import { userService } from '../../helpers/backend/user.service';
import router from '../../router/index';

const user = JSON.parse(localStorage.getItem('user'));

export const state = {
    currentUser: user ? user : null,
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return state.currentUser;
    }
}
    

export const actions = {
    // Logs in the user.
    
    changeTokens({ commit }, {accessToken, refreshToken}){
        let token = {
            accessToken: accessToken,
            refreshToken: refreshToken
        }
        commit('SET_NEW_TOKENS', token);
    },
    login({ dispatch, commit }, { email, password }) {
        //commit('loginRequest', { email });

        userService.login(email, password)
            .then(
                user => {
                    commit('SET_CURRENT_USER', user);
                    dispatch('users/getAvatarControl', {userId: user.user.id}, {root: true})
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // Logout the user
    logout({ dispatch, commit }, { email, refreshToken }) {
        return new Promise((resolve, reject) => {
            userService.logout(email, refreshToken)
            .then(user=>{
                commit('logout');
                resolve(user)
            })
            .catch(err=>{
                dispatch('notification/error', err, { root: true });
                reject(err)
            })
        })
    },
    // reset password
    resetPassword({ dispatch }, { email }) {
        return new Promise((resolve, reject) => {
        userService.resetPassword(email)
            .then(
                response => {
                    let message = response.data;
                    resolve(message);
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                    reject(error);
                }
            );
        });
    },
    // change password
    changePassword({ dispatch }, { email, password, resetToken }) {

        userService.changePassword(email, password, resetToken)
            .then(
                response => {
                    console.log(response);
                    dispatch('notification/success', 'Password changed successfully', { root: true });
                    router.push('/login');
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                }
            );
    },

    // register the user
    registeruser({ dispatch, commit }, user) {
        return new Promise((resolve, reject) => {
            commit('registerRequest', user);
            userService.register(user)
                .then(
                    user => {
                        commit('registerSuccess', user);
                        dispatch('notification/success', 'Registration successful', { root: true });
                        commit('SET_CURRENT_USER', user);
                        router.push('/');
                        resolve(user);
                    },
                    error => {
                        commit('registerFailure', error);
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        });
    }
};

export const mutations = {
    SET_NEW_TOKENS(state, token){
        state.currentUser.tokens = token
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue;
    },
    logout(state) {
        state.currentUser = null;
    },
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};


<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import store from "@/state/store";
import env from "../src/utils/env";
import axios from "axios";
import { authComputed, usersMethods } from "@/state/helpers";
export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  data() {
    return {
      user: null,
    };
  },
  created() {
    setTimeout(() => {
      this.retrieveAvatar();
    }, 2000);
    // setTimeout(this.startWebSocket(), 5000);
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...usersMethods,
    startWebSocket(user) {
      this.user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        store.dispatch("auth/startWs", user, { root: true });
      } else if (this.user) {
        store.dispatch("auth/startWs", this.user, { root: true });
      }
    },
    async sendRefreshToken() {
      await axios
        .post(env.base_url + "/auth/refresh-token", {
          email: this.loggedIn.user.email,
          refreshToken: this.loggedIn.token.refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            store.dispatch("auth/changeTokens", {
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...this.loggedIn,
                token: {
                  accessToken: res.data.accessToken,
                  refreshToken: res.data.refreshToken,
                },
              })
            );
            this.startWebSocket({
              ...this.loggedIn,
              token: {
                accessToken: res.data.accessToken,
                refreshToken: res.data.refreshToken,
              },
            });
          }
        });
    },
    async retrieveAvatar() {
      this.user = JSON.parse(localStorage.getItem("user"));
      if (this.user) {
        let params = {
          userId: this.loggedIn.user.id,
        };
        await this.getAvatarControl(params);
      }
    },
  },
  watch: {
    /* getWsMessage(newVal){
      let msgType = newVal.data.split(" ")
      if(msgType[0] === "403"){
          this.sendRefreshToken();
      }
    }, */
  },
};
</script>
import { devicesService } from '../../helpers/backend/devices.service';

export const state = {
    allDevices: {},
    devices: {},
    devicesTransport: {},
    device: {},
    events: {},
    export: {},
    interventionDevice: {},
}

export const getters = {
    getAllDevices(state) {
        return state.devices
    },
    getDevices(state) {
        return state.devices
    },
    getDevicesTransport(state) {
        return state.devicesTransport
    },
    getDevice(state) {
        return state.device
    },
    getEvents(state){
        return state.events
    },
    getInterventionDevice(state){
        return state.interventionDevice
    }
}

export const actions = {
    getAllDevicesBy({ commit }, params) {
        return devicesService.getAllDevices(params)
            .then(
                devices => {
                    commit("GET_ALL_DEVICES_SUCCESS", devices);
                    return Promise.resolve(devices)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    getDevicesBy({ commit }, params) {
        return devicesService.getDevices(params)
            .then(
                devices => {
                    commit("GET_DEVICES_SUCCESS", devices);
                    return Promise.resolve(devices)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    exportDevicesXLS({commit}, params) {
        return devicesService.exportDevicesXLS(params)
            .then(
                res => {
                    if(res.status !== 204){
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `offline--${params.from}-${params.to}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        commit("EXPORT_SUCCESS", res)
                        return Promise.resolve({status: true});
                    }else{
                        return Promise.resolve({status: false});
                    }
                    
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    exportAllDevicesXLS({commit}) {
        return devicesService.exportAllDevicesXLS()
            .then(
                res => {
                    if(res.status !== 204){
                        const url = window.URL.createObjectURL(new Blob([res.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `All-Devices.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        commit("EXPORT_SUCCESS", res)
                        return Promise.resolve({status: true});
                    }else{
                        return Promise.resolve({status: false});
                    }
                    
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    getDevicesTransportBy({ commit }, params) {
        return devicesService.getDevicesTransport(params)
            .then(
                devices => {
                    commit("GET_DEVICES_TRANSPORT_SUCCESS", devices);
                    return Promise.resolve(devices)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    getDeviceInfos({ commit }, id) {
        return devicesService.getDeviceInfos(id)
            .then(
                device => {
                    commit("GET_DEVICE_INFOS_SUCCESS", device);
                    return Promise.resolve(device)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    getDeviceInfosBySerial({ commit }, serial) {
        return devicesService.getDeviceInfosBySerial(serial)
            .then(
                device => {
                    commit("GET_DEVICE_INFOS_SUCCESS", device);
                    return Promise.resolve(device)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    getEventsBy({ commit }, id){
        return devicesService.getEvents(id)
            .then(
                events => {
                    commit("GET_EVENTS_SUCCESS", events);
                    return Promise.resolve(events)
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    addDevice({ commit }, params) {
        return devicesService.addDevice(params)
            .then(
                device => {
                    commit("ADD_DEVICE_SUCCESS", device)
                    return Promise.resolve(device);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    updateDevice({ commit }, params) {
        return devicesService.updateDevice(params)
            .then(
                action => {
                    commit("UPDATE_DEVICE_SUCCESS", action)
                    return Promise.resolve(action);
                },
                error => {
                    return Promise.reject(error)
                }
            );
    },
    delDevice({ commit }, id) {
        return devicesService.delDevice(id)
            .then(
                device => {
                    commit("DEL_DEVICE_SUCCESS", id)
                    return Promise.resolve(device);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },
    interventionOffline({ commit }, params) {
        return devicesService.interventionOffline(params)
            .then(
                device => {
                    commit("INTERVENTION_OFFLINE_SUCCESS", device)
                    return Promise.resolve(device);
                },
                error => {
                    return Promise.reject(error);
                }
            );
    },

    clearDevices({ commit }) {
        commit("CLEAR_DEVICES")
    }
};

export const mutations = {
    GET_ALL_DEVICES_SUCCESS(state, devices) {
        state.devices = devices;
    },
    GET_DEVICES_SUCCESS(state, devices) {
        state.devices = devices;
    },
    EXPORT_SUCCESS(state, res) {
        state.export = res;
    },
    GET_DEVICES_TRANSPORT_SUCCESS(state, devices) {
        state.devicesTransport = devices;
    },
    GET_DEVICE_INFOS_SUCCESS(state, device) {
        state.device = device;
    },
    GET_EVENTS_SUCCESS(state, events){
        state.events = events;
    },
    ADD_DEVICE_SUCCESS(state, device){
        let results = [...state.devices.results, device]
        state.devices = {...state.devices, results: results}
    },
    UPDATE_DEVICE_SUCCESS(state, device) {
        if(state.devices.results){
            let results = state.devices.results.map(obj => obj.id == device.id ? device : obj)
            state.devices = {...state.devices, results: results}
        }
    },
    DEL_DEVICE_SUCCESS(state, id){
        let results = state.devices.results.filter(obj => obj.id != id)
        state.devices = {...state.devices, results: results}
    },
    INTERVENTION_OFFLINE_SUCCESS(state, device){
        state.interventionDevice = device;
    },
    CLEAR_DEVICES(state) {
        state.devices = {}
    },
};